.main-logo {
  width: 70%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}

.sub-menu a {
  margin-left: 5% !important;
}

